import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserInfos from '../../components/UserInfo/UserInfos';
import AccordionCustom from '../../components/AccordionCustom/AccordionCustom';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { useUserContext } from '../../hooks/auth/UserProvider';
import { useNavigate } from 'react-router-dom';
import { checkIfUserIsCompanyOrAssociation, checkUserEnabled } from '../../helpers/authorizedUser';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import MarketList from '../../components/Markets/MarketList';

interface DefaultAssocAndCompanyPageProps{
    children: ReactNode;
}

const DefaultCompanyAndAssociationPage: React.FC<DefaultAssocAndCompanyPageProps> = ({children}) => {
  const {t} = useTranslationContext();

  // utente autorizzato
  const {user, auth} = useUserContext();
  const navigate = useNavigate();
  const {checkIsAuth} = useUserContext();

  useEffect(()=>{
    if (!auth.isLoading) {
        if (!auth.isAuthenticated) {
            navigate('/');
        } else {
            if(user){
                if(!checkUserEnabled(user)){
                    navigate('/');
                }
                if(!checkIfUserIsCompanyOrAssociation(user)){
                    navigate('/')
                }
            }
        }
    }
    checkIsAuth().finally();
  },[auth.isAuthenticated, auth.isLoading, navigate, user])

  return (
    <>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {
        auth.isAuthenticated && <>
            {children}
          </>
        }
        
      </Box>
    </>
  );
};

export default DefaultCompanyAndAssociationPage;
