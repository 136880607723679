import { useEffect } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import CancelIcon  from '@mui/icons-material/CancelOutlined';
import { useTranslation } from "react-i18next";

export default function NotFound () {
    const { t } = useTranslation();

    return (
        <Container 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent:'center',
            }}
        >
            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'red', color: 'white', alignItems:'center', 
                marginTop: '50px' 
            }}>
                <CancelIcon sx={{fontSize:50}} />
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                {t("404")}
            </Typography>
            
            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography variant="h4">
                    {t("Pagina non trovata")}
                </Typography>
            </Box>
        </Container>
    )
}