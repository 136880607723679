import React, { useState } from 'react';
import { TextField, FormHelperText } from '@mui/material';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';

interface TextFieldCustomProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  errorText: string;
  regex?: RegExp;
  disable?: boolean;
  required?:boolean;
  minPasswordLength?:number;
}

function removeFirstSpaceChar(str:string) {
    if (str.charAt(0) === ' ') {
      return str.slice(1);
    } else {
      return str;
    }
}

const TextFieldPasswordCustom: React.FC<TextFieldCustomProps> = ({
  label,
  regex,
  value,
  onChange,
  errorText,
  disable=false,
  required=true,
  minPasswordLength=1
}) => {
  const [error, setError] = useState(false);
  const {t} = useTranslationContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = removeFirstSpaceChar(event.target.value)
    onChange(value);
  };

  const handleOnBlur = () => {
    if(value.length > 0){
      if(regex){
        setError(!regex.test(value)); 
      }
      if(minPasswordLength > 1 ){
        if (value.length < minPasswordLength){
          setError(true)
        }
      } 
    }
  }

  return (
    <>
        <TextField
            margin="normal"
            required={required}
            fullWidth
            id={label}
            label={t(label)}
            name={label}
            autoComplete={label}
            value={value}
            type="password"
            onChange={handleChange}
            error={error}
            disabled={disable}
            helperText={
                error ? t(errorText) : ""
            }
            onBlur={()=>{
              handleOnBlur();
            }}
            onFocus={() => {
              onChange("");
              setError(false);
            }}
        />
    </>
  );
};

export default TextFieldPasswordCustom;
