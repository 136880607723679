import { UpdateUserPasswordRequestModel } from "../../models/changePassword/changePassword";
import httpClient from "../../utilities/http-client";

export const changePasswordRequest = async (model: UpdateUserPasswordRequestModel) => {
	try {
		const { data } = await httpClient.post<boolean>(`/users/change-password/`, model);
		return data;
	} catch (e:any) {
		throw e.response
	}
};