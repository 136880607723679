import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslationContext } from '../../hooks/translations/translationsProvider'; // Assuming you have a translation context
import lastDate18YearsAgo from '../../utilities/lastDate18YearsAgo';

interface DatePickerCustomProps {
    label: string;
    selectedDate?: Dayjs | undefined | null;
    handleChangeDate: (date: Dayjs | null) => void;
    
    maxData?: Dayjs;
    min18YearsActive?:boolean;
    disable?: boolean;
    error?: boolean;
    errorMessage?: string;
    dataPickerProps?: React.ComponentPropsWithoutRef<typeof DatePicker>; 
    stackProps?: React.ComponentPropsWithoutRef<typeof Stack>; 
}

const DatePickerCustom: React.FC<DatePickerCustomProps> = ({
    label,
    maxData,
    disable=true,
    selectedDate,
    handleChangeDate,
    error=false,
    errorMessage,
    dataPickerProps={},
    stackProps={}
}) => {
    const { t } = useTranslationContext();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
                sx={{
                    marginTop: '16px',
                    marginBottom: '8px'
                }}
                {...stackProps}
            >
                <DatePicker
                    format="DD/MM/YYYY"
                    disableFuture
                    label={label}
                    views={['day', 'month', 'year']}
                    maxDate={maxData}
                    value={selectedDate || dayjs("1900-01-01")} // rimosso per mettere il default value - fare controlli
                    onChange={(date) => handleChangeDate(date)}
                    slotProps={{
                        textField: {
                        required: true,
                        error: error,
                        helperText: error ? errorMessage : ''
                        }
                    }}
                    disabled={disable}
                    {...dataPickerProps}
                />
            </Stack>
        </LocalizationProvider>
    );
};

export default DatePickerCustom;
