import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Link
} from '@mui/material';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { BLACK } from '../../theme/colors';
import { Transactions } from '../../models/dashboard/Transactions';
import { formatToTwoDecimals } from '../../helpers/numberFormats';
import { formatDate } from '../../helpers/FormatDate';
import settings from '../../utilities/settings';
import { lastTransactionsRequest } from '../../hooks/actions/tokens';


const LastWithdrawTransactionsTable: React.FC<{status:boolean}> = ({ status }) => {
  const theme = useTheme();
  const {t} = useTranslationContext();
  const [lastTransactions, setLastTransactions] = React.useState<Transactions[] | null>(null);

  useEffect(()=>{
    handleLastTransactions()
        .then(resp=>{setLastTransactions(resp)})
        .catch(e=>{console.error(e)});
  },[status])

  const handleLastTransactions = async (): Promise<Transactions[] | null> => {
    try { 
        setLastTransactions(null);
        const resp = await lastTransactionsRequest();
        return resp;
    } catch (e) {
        console.error(e);
        return null;
    }
  };

  return (
    <>
      {lastTransactions && <>
        <Box sx={{marginTop:'50px'}} />
        <TableContainer component={Paper} sx={{boxShadow:5}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight:'bold'}}>{t("Ammontare Asso")}</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>{t("Data di richiesta")}</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>{t("Data di conferma")}</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>{t("Tipo di Transazione")}</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>{t("Informazioni")}</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>{t("Hash")}</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>{t("Indirizzo Wallet")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lastTransactions && lastTransactions.map((transaction) => (
                <TableRow key={transaction.hash}>
                  <TableCell
                    sx={{
                      color: transaction.tokensAmount < 0 ? 'red' : undefined
                    }}
                  >
                    {formatToTwoDecimals(transaction.tokensAmount)}
                  </TableCell>

                  <TableCell>{formatDate(transaction.dataEvent)}</TableCell>

                  <TableCell
                    sx={{
                      color: transaction.dataConfirmed === null ? 'orange' : undefined
                    }}
                  >
                    { transaction.dataConfirmed === null ? "Pending.." : formatDate(transaction.dataConfirmed)}
                  </TableCell>

                  <TableCell>{t(transaction.type)}</TableCell>

                  <TableCell
                    sx={{
                      color: transaction.info === "Not Confirmed"  ? 'orange' : undefined
                    }}
                  >{t(transaction.info)}</TableCell>

                  <TableCell>
                    {transaction.hash ? (
                      <Link
                        href={`${settings.Token.Blockchain.ExplorerUrl}/tx/${transaction.hash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {transaction.hash}
                      </Link>
                    ) : (
                      <>
                        {transaction.hash}
                      </>
                    )}
                  </TableCell>

                  <TableCell>
                    {transaction.addressWallet ? (
                      <Link
                        href={`${settings.Token.Blockchain.ExplorerUrl}/address/${transaction.addressWallet}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {transaction.addressWallet}
                      </Link>
                    ) : (
                      <>
                        {transaction.addressWallet}
                      </>
                    )}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{marginTop:'20px'}}>
          <Link href="/transactions" sx={{ color:BLACK, textDecoration: 'none' }}>
              {t("Mostra tutto")}
          </Link>
        </Box>
      </>}
    </>
  );
};

export default LastWithdrawTransactionsTable;

