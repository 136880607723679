import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { useTranslationContext } from '../../hooks/translations/translationsProvider'
import UserManagement from './Admin/Users'
import { GRAY_LIGHT_RGB_BORDER, WHITE } from '../../theme/colors'
import PurchasesManagement from './Admin/Purchases'
import { isMobile } from 'react-device-detect'

const AdminPanel = () => {
  const [selectedSection, setSelectedSection] = useState<string>('users')
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { t } = useTranslationContext();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  // Automatically show sidebar on MD and up
  React.useEffect(() => {
    setIsSidebarOpen(isMdUp)
  }, [isMdUp])

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      {/* Sidebar Menu */}
      <Box
        sx={{
          marginTop:'-40px',
          width: isMobile ? '200px' : '25%',
          backgroundColor: WHITE,
          position: {
            xs: 'fixed',
            md: 'relative',
          },
          left: {
            xs: isSidebarOpen ? '0' : (isMobile ? '-200px' : '-25%'),
            md: 0,
          },
          transition: theme.transitions.create(['left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
        //   height: '100vh', 
          minHeight: '100vh', // Ensures it won't shrink when content is small %
          borderRight: '1px solid ' + GRAY_LIGHT_RGB_BORDER,
          // borderTop: '1px solid ' + GRAY_LIGHT_RGB_BORDER,
          borderLeft: {md: '1px solid ' + GRAY_LIGHT_RGB_BORDER}
        }}
      >
        {/* Toggle Button - inside the sidebar now */}
        {!isMdUp && (
          <IconButton
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            sx={{
              position: 'absolute',
              top: '20px', // Top space from the sidebar
              left: 'calc(100% - 46px)', // Position at the right of the sidebar
              transform: 'translateX(100%)',
              bgcolor: 'background.paper',
              boxShadow: 1,
              borderRadius: '0px',
              border: '1px solid',
              borderColor: 'divider',
              transition: theme.transitions.create(['left'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
              }),
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}

        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">{t('Admin Panel')}</Typography>
        </Box>

        <Divider />

        {/* Menu Items */}
        <Box sx={{ padding: 2, overflow: 'auto' }}>
          <List>
            <ListItem
              button
              selected={selectedSection === 'users'}
              onClick={() => setSelectedSection('users')}
            >
              <ListItemText primary={t("Users")} />
            </ListItem>

            <ListItem
              button
              selected={selectedSection === 'purchases'}
              onClick={() => setSelectedSection('purchases')}
            >
              <ListItemText primary={t("Acquisti")} />
            </ListItem>

            <ListItem
              button
              selected={selectedSection === 'settings'}
              onClick={() => setSelectedSection('settings')}
            >
              <ListItemText primary={t('Settings')} />
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '75%',
          },
          padding: 2,
          ml: {
            xs: 0,
            md: 0,
          },
          overflow: 'auto',
        }}
      >
        {selectedSection === 'users' && <UserManagement/>}
        {selectedSection === 'purchases' && <PurchasesManagement/>}
        {selectedSection === 'settings' && <>SETTINGS</>}
      </Box>
    </Box>
  )
}

export default AdminPanel
