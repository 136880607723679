import { createTheme } from "@mui/material/";
import { breakpoints } from "./breakpoints";
import { palette, BLACK, BLUE_MAIN, GRAY_MAIN, WHITE, GRAY_LIGHT, BLUE_LIGHT, GREEN_LIGHT, BLUE_DARK } from "./colors";
import { spacing } from "./spacing";
import { fontWeightClasses, typography, worksans } from "./typography";

export const theme = createTheme({
	typography,
	palette,
	breakpoints,
	spacing,
	shape: {
		borderRadius: 2,
	},
	components:{
		MuiGrid:{
			styleOverrides:{
				root:{
					
				}
			}
		},
		MuiButton:{
			styleOverrides:{
				
				root:{
					height:'30px',
					width:'100%',
					// maxWidth:'100px',
					borderRadius:'15px',
					color: BLUE_MAIN,
					border:'2px solid '+ BLUE_MAIN,
					fontSize:'0.7em',
					//padding:'0px',
				}
			}
		},
		MuiSelect:{
			styleOverrides:{
				root:{
					height:'30px',
					color: BLUE_MAIN,
					fontSize:'0.7em',
					'& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
						paddingTop: '6px',
						paddingLeft:'10px',
					},
					
					'& .MuiSelect-icon':{
						color: BLUE_MAIN
					},
					
					'& .MuiInputBase-root::before': {
						borderBottomColor: BLUE_MAIN
					},
					'& .MuiInputBase-root::after': {
						borderBottomColor: BLUE_DARK
					},
				},
			}
		},
		MuiCard:{
			styleOverrides:{
				root:{
					minHeight:'50px',
					width:'100%',
					borderRadius:'5px',
					color: WHITE,
					backgroundColor: BLUE_MAIN,
					fontSize:'1em',
					display:'flex',
					flexDirection:'column',
					justifyContent:'center',
				}
			}
		},
		MuiIconButton:{
			styleOverrides:{
				root:{
					// color:WHITE,
					// width:'100%',
					// height:'100%'
				}
			}
		}
		// MuiDialogTitle: {
		// 	styleOverrides:{
		// 		root:{
		// 			backgroundColor: 'blue',
		// 			padding: spacing(4),
		// 			[breakpoints.down("md")]: {
		// 				padding: spacing(3),
		// 			},
		// 			paddingBottom: 0,
		// 			fontSize: 27,
		// 			fontWeight: "normal",
		// 			textAlign: "center",
		// 		},
		// 	}
		// },
	},
});

