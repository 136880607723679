import React, { useEffect, useState } from 'react';
import { UserInfosModel } from '../../models/user/UserInfosModel';
import { fetchMe } from '../../hooks/actions/users';
import PrivateUser from './PrivateUser';
import AssociationOrCompanyUser from './AssociationOrCompanyUser';

const UserInfos: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfosModel | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchMe().then(resp => {
      setUserInfo(resp);
    }).catch(error =>{
      setErrorMsg(error);
      setError(true);
    });
  }, []);

  if (!userInfo) return <div>Loading...</div>;

  const isPrivateUser = userInfo.isPrivate;

  return (
    <div>
      {isPrivateUser ? (
            <PrivateUser user={userInfo}/>
        ) : (
           <AssociationOrCompanyUser user={userInfo} />
        )
      }
    </div>
  );
};

export default UserInfos;
