import { AdminBuyResponseModel, EBuyStatus, EBuyType, IBuyModifyRequestModel, IConfirmBuyRequestModels } from "../../models/buy/Buy";
import { CompanyAssociationInfoRequestModel, EApprovedFromAssociation, ECompanyOrAssociationStatus, PrivateUserInfoRequestModel, UserInfosModel, UsersListResponseModel } from "../../models/user/UserInfosModel";
import httpClient from "../../utilities/http-client";

export const usersPrivateList = async (from:number, count:number, search?:string | null, status?:EApprovedFromAssociation | null, approved?:boolean | null): Promise<UsersListResponseModel> => {
	try {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) {
            params.append("status", status.toString());
        }
        if (approved !== null && approved !== undefined) params.append("approved", approved.toString());

        const queryString = params.toString();
        const url = `/admin/users/private/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<UsersListResponseModel>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const usersCompanyAssociationList = async (from:number, count:number, search?:string | null, status?:ECompanyOrAssociationStatus | null, approved?:boolean | null): Promise<UsersListResponseModel> => {
	try {
        const params = new URLSearchParams();
        
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) {
            params.append("status", status.toString());
        }
        if (approved !== null && approved !== undefined) params.append("approved", approved.toString());

        const queryString = params.toString();
        const url = `/admin/users/companies-associations/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<UsersListResponseModel>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const buyRequestList = async (from:number, count:number, search?:string | null, status?:EBuyStatus | null, type?:EBuyType | null, dataFrom?: Date | null, dataTo?: Date | null): Promise<AdminBuyResponseModel[]> => {
	try {
        const params = new URLSearchParams();
        if(dataFrom) params.append("dataFrom", dataFrom.toISOString().substring(0, 16));
        if(dataTo) params.append("dataTo", dataTo.toISOString().substring(0, 16));
        if (search) params.append("search", search);
        if (status !== null && status !== undefined) params.append("status", status.toString());

        if (type !== null && type !== undefined) params.append("type", type.toString());

        const queryString = params.toString();
        const url = `/admin/buy/${from}/${count}/` + (queryString ? `?${queryString}` : "");

		const { data } = await httpClient.get<AdminBuyResponseModel[]>(url);
		return data;
	} catch (e:any) {
		throw e.response
	}
};

export const buyModifyRecord = async (model: IBuyModifyRequestModel): Promise<AdminBuyResponseModel> => {
    try{      
        const { data } = await httpClient.put<AdminBuyResponseModel>(`/admin/buy/`, model);
		return data;
    }catch(e:any){
        throw e.response;
    }
}

export const buyConfirmTransaction = async (model: IConfirmBuyRequestModels) => {
    try{
        const { data } = await httpClient.post<AdminBuyResponseModel>(`/admin/confirm/buy/`, model);
        return data;
    }catch(e:any){
        throw e.response;
    }
}

export const putAdminUserPrivate = async (model: PrivateUserInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<PrivateUserInfoRequestModel>(`/admin/users/`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};

export const putAdminAssociationOrCompany = async (model:CompanyAssociationInfoRequestModel) => {
	try {
		const { data } = await httpClient.put<CompanyAssociationInfoRequestModel>(`/admin/company/`, model);
		return data;
	} catch (e:any) {
		throw e;
	}
};