import { Contract, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import settings from "../../utilities/settings";
import tokenAbi from '../../config/ABI/tokenAbi.json';
import depositAbi from '../../config/ABI/depostiAbi.json';
import { useWeb3Context } from "@w3connector/web3provider";

function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!address) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any
) {
  const {web3Hooks} = useWeb3Context();
  return useMemo(() => {
    if (!address || !ABI || !web3Hooks.isActive || !web3Hooks.provider) return null;
    try {
      return getContract(address, ABI, web3Hooks.provider, web3Hooks.account);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, web3Hooks.provider, web3Hooks.chainId, web3Hooks.account]) as T;
}

export function useTokenContract() {
  return useContract(settings.Token.AssoContract, tokenAbi); 
}

export function useDepositContract() {
  return useContract(settings.DepositContract.ContractAddress, depositAbi);
}
