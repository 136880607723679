import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Grid } from '@mui/material';
import { CompanyAssociationInfoRequestModel, PrivateUserInfoRequestModel, UserInfosModel } from '../../models/user/UserInfosModel';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import TextFieldCustom from '../TextField/TextFieldCustom';
import SearchNations from './searchNation';
import DatePickerCustom from '../DatePicker/DatePickerCustom';
import dayjs, { Dayjs } from 'dayjs';
import lastDate18YearsAgo from '../../utilities/lastDate18YearsAgo';
import { BLUE_DARK, GRAY_LIGHT, GRAY_MAIN, WHITE } from '../../theme/colors';
import { getAssociationStatusLabel } from '../../helpers/approverFromAssociationHelper';
import { putMeAssociationOrCompany, putMePrivate } from '../../hooks/actions/users';
import AlertSaveModal from './AlertSaveModal';
import { useWaiter } from '../../hooks/waiter/WaiterProvider';
import { Snackbar, Alert } from '@mui/material';

interface CompanyOrAssociationUserProps {
  user: UserInfosModel; 
}

const AssociationOrCompanyUser: React.FC<CompanyOrAssociationUserProps> = ({ user }) => {
  const { t } = useTranslationContext();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<CompanyAssociationInfoRequestModel>({
    userid: user.userid || '', 
    isPrivate: user.isPrivate,
    conventioned: user.conventioned,
    email: user.email,
    enabledUser: user.enabledUser === true,
    username: user.username,
    userType: user.userType,
    refCode: user.refCode || '',
    association: user.association || '',
    companyOrAssociationStatus: user.companyOrAssociationStatus || 0,
    
    userInfoId: user.companyAssociationInfo?.id || '',

    // Dati relativi all'amministratore aziendale
    nome: user.companyAssociationInfo?.nome || '',
    cognome: user.companyAssociationInfo?.cognome || '',
    born: user.companyAssociationInfo?.born || undefined,
    address: user.companyAssociationInfo?.address || '',
    comuneDiNascita: user.companyAssociationInfo?.comuneDiNascita || '',
    statoDiNascita: user.companyAssociationInfo?.statoDiNascita || '',
    city: user.companyAssociationInfo?.city || '',
    cap: user.companyAssociationInfo?.cap || 0,
    provincia: user.companyAssociationInfo?.provincia || '',
    nation: user.companyAssociationInfo?.nation || '',
    codiceFiscale: user.companyAssociationInfo?.codiceFiscale || '',

    // Dati relativi all'azienda
    nomeAziendaAssociazione: user.companyAssociationInfo?.nomeAziendaAssociazione || '',
    addressSedeLegale: user.companyAssociationInfo?.addressSedeLegale || '',
    citySedeLegale: user.companyAssociationInfo?.citySedeLegale || '',
    capSedeLegale: user.companyAssociationInfo?.capSedeLegale,
    nationSedeLegale: user.companyAssociationInfo?.nationSedeLegale || '',
    provinciaSedeLegale: user.companyAssociationInfo?.provinciaSedeLegale || '',
    partitaIvaVat: user.companyAssociationInfo?.partitaIvaVat,
    codiceFiscaleAziendale: user.companyAssociationInfo?.codiceFiscaleAziendale || '',
    websiteAziendale: user.companyAssociationInfo?.websiteAziendale,
    telefonoAziendale: user.companyAssociationInfo?.telefonoAziendale,
    mailRichiestePubblicheAziendale: user.companyAssociationInfo?.mailRichiestePubblicheAziendale
  });

  const [showPopup, setShowPopup] = useState(false);
  const {setWaiterView} = useWaiter();
  const [initialFormData, setInitialFormData] = useState({ ...formData }); // Salva i dati iniziali

  const [modalOpen, setModalOpen] = useState(false);
  const bornDate = initialFormData.born ? dayjs(initialFormData.born) : dayjs("01-01-1900")
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(bornDate); 
  const [bornError, setBornError] = useState(false);
  const [bornErrorMessage, setBornErrorMessage] = useState('');
  const [maxData] = useState<Dayjs | undefined>(dayjs(lastDate18YearsAgo()));

  const [mainError, setMainError] = useState<boolean>(false);
  const [mainErrorMex, setMainErrorMex] = useState<string>("");

  const handleChange = (value: string, key: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value, // Directly update with the string value
    }));
  };

  const handleEdit = () => {
    setMainError(false); // cancello eventuali errori
    setMainErrorMex("");

    setIsEditing(true);
  };

  const handleSave = () => {
    setMainError(false); // cancello eventuali errori
    setMainErrorMex("");
    console.log(formData)
    handleCloseModal(); // chiudo il modal

    if(JSON.stringify(formData) === JSON.stringify(initialFormData)){ // verifico che i dati non siano gli stessi
      setIsEditing(false);

      setShowPopup(true); // apertura e chiusura del popup
      setTimeout(() => {
        setShowPopup(false);
      }, 10000);

      return;
    }
    setWaiterView(true); // avvio il waiter
    
    putMeAssociationOrCompany(formData).then(resp => {
      setFormData(resp);
      setInitialFormData(resp);
      setWaiterView(false); // termina il waiter
      
      setShowPopup(true); // apertura e chiusura del popup
      setTimeout(() => {
        setShowPopup(false);
      }, 10000);

    }).catch(error => {
      // settare il main error
      setMainError(true);
      const errorMex =  error?.response?.data?.title || 
                        error?.response?.data?.message || 
                        error?.response?.data || 
                        error?.response || 
                        t("Errore durante la modifica dei dati")
      setMainErrorMex(errorMex);
    });

    setWaiterView(false); // termina il waiter
    setIsEditing(false); // disabilito la modifica
  };

  const handleCancel = () => {
    setMainError(false); // cancello eventuali errori
    setMainErrorMex("");

    setFormData(initialFormData); // Reset dei dati al valore iniziale
    setIsEditing(false);
  };

  // const checkForSave = async (): Promise<boolean> =>{
  //   try{
  //     if(

  //     ){
  //       return true;
  //     }
  //     else return false;
  //   }catch(error){
  //     throw error;
  //   }
  // }

  // Conditionally render the component output
  if (!user.userid) {
    return <div>{t("Utente privato non disponibile.")}</div>;
  }

  const handleChangeDate = (date: Dayjs | null) => {
    setBornErrorMessage('')
    if(date !== null && date.isValid()){ // se la data è valida mi setti false, ma se per caso dovesse essere meno di 18 anni fai true all'errore
        setSelectedDate(date)
        setBornError(false)

        if(maxData !== undefined && date > maxData){
            setBornErrorMessage(t("Devi avere più di 18 anni per iscriverti"))
            setBornError(true)
        } 
        setFormData((prev) => ({
          ...prev,
          ['born']: date.toDate(), // Directly update with the string value
        }));
    } 
    if (date !== null && !date.isValid()){
        setBornErrorMessage(t("Data non valida"))
        setBornError(true)
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div>
        <h5>Utente: Azienda o Associazione</h5>
        <form>
          <Typography sx={{
            padding:'16px',
            color: 'GrayText',
            fontSize:'0.9rem'
          }}>
            {t("Dati Azienda o Associazione") }
          </Typography>
          <TextFieldCustom
            label={t("Nome Azienda o Associazione")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.nomeAziendaAssociazione || ''}
            errorText={t("Richieste lettere e spazi")}
            onChange={(value) => handleChange(value, 'nomeAziendaAssociazione')}
            disable={(!initialFormData.nomeAziendaAssociazione) && isEditing ? false : true}
            required
          />

          <TextFieldCustom
            label={t("Codice Fiscale Azienda o Associazione")}
            regex={/^[A-Za-z0-9]*$/}
            value={formData.codiceFiscaleAziendale || '' }
            onChange={(value) => handleChange(value, 'codiceFiscaleAziendale')}
            required={true}
            errorText={t("Richiesti lettere e numeri")}
            disable={(!initialFormData.codiceFiscaleAziendale) && isEditing ? false : true}
          />

          <TextFieldCustom
            label={t("Partita Iva Azienda o Associazione")}
            regex={/^[A-Za-z0-9]*$/}
            value={formData.partitaIvaVat || '' }
            onChange={(value) => handleChange(value, 'partitaIvaVat')}
            required={true}
            errorText={t("Richiesti lettere e numeri")}
            disable={(!initialFormData.partitaIvaVat) && isEditing ? false : true}
          />

          <SearchNations 
            label={t("Stato sede legale")}
            nazione={formData.nationSedeLegale || ''} 
            setNazione={(value) => handleChange(value, 'nationSedeLegale')} 
            disable={(!initialFormData.nationSedeLegale) && isEditing ? false : true}
          />
          <TextFieldCustom
            label={t("Indirizzo sede legale")}
            regex={/^[A-Za-z0-9 ,\/-]*$/}
            value={formData.addressSedeLegale || ''}
            onChange={(value) => handleChange(value, 'addressSedeLegale')}
            required={true}
            errorText={t("Richieste solo lettere, spazi, numeri, virgola e meno e /")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Città sede legale")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.citySedeLegale || ''}
            onChange={(value) => handleChange(value, 'citySedeLegale')}
            required={true}
            errorText={t("Richieste lettere e spazi")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("CAP sede legale")}
            regex={/^[0-9]+$/}
            value={formData.capSedeLegale?.toString() || '' }
            onChange={(value) => handleChange(value, 'capSedeLegale')}
            required={true}
            errorText={t("Richiesti solo Numeri")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Provincia sede legale")}
            regex={/^[A-Za-z]+$/}
            value={formData.provinciaSedeLegale || '' }
            onChange={(value) => handleChange(value, 'provinciaSedeLegale')}
            required={true}
            errorText={t("Richieste solo Lettere")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Email")}
            value={formData.email || '' }
            errorText={t("")}
          />
          <TextFieldCustom
            label={t("Associazione di appartenenza")}
            value={formData.association || '' }
            errorText={t("")}
          />




          <Typography sx={{
            padding:'16px',
            color: 'GrayText',
            fontSize:'0.9rem'
          }}>
            {t("Dati Pubblici") }
          </Typography>
          <TextFieldCustom
            label={t("Email per richieste utenti")}
            regex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
            value={formData.mailRichiestePubblicheAziendale || '' }
            onChange={(value) => handleChange(value, 'mailRichiestePubblicheAziendale')}
            errorText={t("Formato mail non corretto")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Sito web aziendale")}
            regex={/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/}
            value={formData.websiteAziendale || '' }
            onChange={(value) => handleChange(value, 'websiteAziendale')}
            errorText={t("Formato sito web non corretto")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Telefono per richieste utenti")}
            regex={/^\+?(\d{1,3})?[-. ]?\(?\d{1,4}\)?[-. ]?\d{1,4}[-. ]?\d{1,9}$/}
            value={formData.telefonoAziendale || '' }
            onChange={(value) => handleChange(value, 'telefonoAziendale')}
            errorText={t("Formato telefonico non corretto")}
            disable={!isEditing}
          />


          
          <Typography sx={{
            padding:'16px',
            color: 'GrayText',
            fontSize:'0.9rem'
          }}>
            {t("Dati Amministratore") }
          </Typography>

          <TextFieldCustom
            label={t("Nome")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.nome || ''}
            errorText={t("Richieste lettere e spazi")}
            onChange={(value) => handleChange(value, 'nome')}
            disable={!isEditing}
            required
          />
          <TextFieldCustom
            label={t("Cognome")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.cognome || ''}
            errorText={t("Richieste lettere e spazi")}
            onChange={(value) => handleChange(value, 'cognome')}
            disable={!isEditing}
            required
          />
          <DatePickerCustom
            label={t("Data di Nascita")}
            selectedDate={selectedDate}
            handleChangeDate={handleChangeDate}
            maxData={maxData}
            error={bornError}
            errorMessage={bornErrorMessage}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Comune di nascita")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.comuneDiNascita || ''}
            onChange={(value) => handleChange(value, 'comuneDiNascita')}
            required={true}
            errorText={t("Richieste lettere e spazi")}
            disable={!isEditing}
          />
          <SearchNations 
            label={t("Stato di nascita")}
            nazione={formData.statoDiNascita || ''} 
            setNazione={(value) => handleChange(value, 'statoDiNascita')} 
            disable={!isEditing}
            //disable={(!initialFormData.born) && isEditing ? false : true}
          />
          <TextFieldCustom
            label={t("Indirizzo")}
            regex={/^[A-Za-z0-9 ,\/-]*$/}
            value={formData.address || ''}
            onChange={(value) => handleChange(value, 'address')}
            required={true}
            errorText={t("Richieste solo lettere, spazi, numeri, virgola e meno e /")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Città")}
            regex={/^(?=.*[A-Za-z])[A-Za-z ]+$/}
            value={formData.city || ''}
            onChange={(value) => handleChange(value, 'city')}
            required={true}
            errorText={t("Richieste lettere e spazi")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("CAP")}
            regex={/^[0-9]+$/}
            value={formData.cap?.toString() || '' }
            onChange={(value) => handleChange(value, 'cap')}
            required={true}
            errorText={t("Richiesti solo Numeri")}
            disable={!isEditing}
          />
          <TextFieldCustom
            label={t("Provincia")}
            regex={/^[A-Za-z]+$/}
            value={formData.provincia || '' }
            onChange={(value) => handleChange(value, 'provincia')}
            required={true}
            errorText={t("Richieste solo Lettere")}
            disable={!isEditing}
          />

          <SearchNations 
            label={t("Nazione")}
            nazione={formData.nation || ''} 
            setNazione={(value) => handleChange(value, 'nation')} 
            disable={!isEditing}
          />

          <TextFieldCustom
            label={t("Codice Fiscale")}
            regex={/^[A-Za-z0-9]*$/}
            value={formData.codiceFiscale || '' }
            onChange={(value) => handleChange(value, 'codiceFiscale')}
            required={true}
            errorText={t("Richiesti lettere e numeri")}
            disable={!isEditing}
          />

          <Typography sx={{
            padding:'16px',
            color: 'GrayText',
            fontSize:'0.9rem'
          }}>
            {/* {t(getAssociationStatusLabel(formData.approvedFromAssociation)) } */}
          </Typography>
        </form>

        {!isEditing ? (
          <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleEdit} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Modifica")}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button variant="outlined" onClick={handleCancel} sx={{
                margin:"10px 0 0 0",
                height: '35px'
              }}>
                {t("Annulla")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="info" onClick={handleOpenModal} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Salva")}
              </Button>
            </Grid>
          </Grid>
        )}


        {mainError && <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
            {mainErrorMex}
          </Typography>
        </>
        }
      </div>

      <AlertSaveModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
      />

      {/* Popup che appare dopo il salvataggio */}
      <Snackbar open={showPopup} anchorOrigin={{vertical: 'bottom', horizontal: "right" }}>
        <Alert severity="success"
          sx={{ 
            border: '2px solid green',
            marginBottom:'30px'
          }}
          onClose={() => setShowPopup(false)}>
          {t("Salvataggio dati avvenuto con successo")}
        </Alert>
      </Snackbar>
    </>
  );
};


export default AssociationOrCompanyUser;
