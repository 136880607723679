import React, { useState, useEffect, useCallback, useRef } from 'react';
import {Box, Typography, Grid, Button, Card} from '@mui/material';
import { BLUE_DARK, BLUE_LIGHT, BLUE_LIGHT_RGB_BORDER, BLUE_MAIN } from '../theme/colors';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import { useLocation } from 'react-router-dom';
import AssociationsItem from '../components/AssociationsItems';
import Search from '../components/Search';
import { associationsListSearch, associationsList } from '../hooks/actions/circuito';
import { LegalEntity } from '../models/circuito/LegacyEntity';
import { useWaiter } from '../hooks/waiter/WaiterProvider';
import { delay } from '../utilities/delay';
import CustomWaiter from '../components/Waiter';
import { isMobile } from 'react-device-detect';

const AssociationsList = () => {
    const {t} = useTranslationContext();
    const location = useLocation();
    const [url, setUrl] = useState<string>('');
    const [search, setSearch] = useState<string>('')
    const [listAssociations, setListAssociations] = useState<LegalEntity[]|undefined>();
    const [searchWaiter, setSearchWaiter] = useState<boolean>(false)

    const {setWaiterView} = useWaiter();

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(()=>{
        setWaiterView(true);
        associationsList().then((resp) => {
            setListAssociations(resp);
            delay(500).then(()=>{
                setWaiterView(false);
            })
        }).catch(e=>{
            console.log(e)
            delay(500).then(()=>{
                setWaiterView(false);
            })
        })
    },[])

    useEffect(()=>{
        setUrl(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`)
    },[location])

    useEffect(()=>{
        const performSearch = async () => {
            try {
                if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
                const minChars = 2;
                let respSearch;
                setSearchWaiter(true)
                debounceTimeout.current = setTimeout(async () => {

                    if (search.length < minChars) {
                        respSearch = await associationsList();
                            
                    } else {
                        respSearch = await associationsListSearch(search);
                    }

                    setListAssociations(respSearch);
                    debounceTimeout.current = null;
                    setSearchWaiter(false)
                }, 1200);
            } catch (e) {
                console.log(e);
                setSearchWaiter(false)
            }
        };

        performSearch();
    },[search])

    const handleInput = async (input: string) => {
        const newString = input.replace(' ', '')
        if(newString === search) return; 

        if(newString.length > 20) return;

        setSearch(newString);
    }

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <Grid container spacing={0}  justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                        <Grid item xs={!isMobile ? 10 : 12} sm={6} md={4} display={'flex'}> 
                            <Search 
                                handleInput={handleInput}
                                search={search}
                                setSearch={setSearch}
                            />
                            
                            <div style={{
                                paddingTop:'3px',
                                paddingLeft:'3px',
                                width:'50px',
                                height:'35px'
                            }}>
                                {searchWaiter && <CustomWaiter size={30} width={40}/>}
                            </div>
                        </Grid>
                    </Grid>

                    <Box sx={{height:'30px'}}/>

                    <Typography variant='h3'>{t("IL CIRCUITO")}</Typography>

                    <Box sx={{height:'40px'}}/>

                    {
                        listAssociations && listAssociations.length > 0 ? (
                            listAssociations.map((entity, index) => (
                            <div key={index}>
                                <Box
                                    sx={{
                                        display:'flex',
                                        justifyContent:'center',
                                        padding:'20px 0 10px 0',
                                        borderTop:'1px solid '+BLUE_LIGHT_RGB_BORDER
                                    }}
                                >
                                    <Typography variant='h4' color={BLUE_DARK}>{ entity.nationSedeLegale }</Typography>
                                </Box>

                                {
                                    entity.associations.map((item, subindex)=>(
                                        <div key={subindex}>
                                            <AssociationsItem 
                                                name={item.nomeAziendaAssociazione} 
                                                nation={item.nationSedeLegale}
                                                mail={item.mailRichiestePubblicheAziendale}
                                                website={item.websiteAziendale} 
                                                phone={item.telefonoAziendale!}
                                                city={item.citySedeLegale}
                                                province={item.provinciaSedeLegale}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        ):(
                            listAssociations !== undefined && <Box id="oma"
                                sx={{
                                    display:'flex',
                                    justifyContent:'center',
                                    padding:'50px 0 10px 0',
                                    //borderTop:'1px solid '+BLUE_LIGHT_RGB_BORDER
                                }}
                            >
                                <Typography variant='body1'>{t("Non ci sono elementi da visualizzare.")}</Typography>
                            </Box>   
                        )
                    }
                </Box>
            </Box>
        </>
    )
}

export default AssociationsList;