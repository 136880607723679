import { useEffect, useRef, useState } from "react";
import { Box, Avatar, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import Login from '@mui/icons-material/Login'
import { delay } from "../utilities/delay";
import { BLUE_MAIN } from "../theme/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWaiter } from "../hooks/waiter/WaiterProvider";
import { AddCreditsForReferralModelRequest } from "../models/credits/CreditsModels";
import { addCreditsForReferral } from "../hooks/actions/credits";

export default function RegisterCallBack () {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const{setWaiterView} = useWaiter();
    const [message, setMessage]=useState("Attendi la fine del processo di registrazione...")
    const [messageColor, setMessageColor]=useState("green")
    const navigate = useNavigate();
    const userId = searchParams.get("userId");
    const userRef = searchParams.get("userRef");
    const token = searchParams.get("code");
    const initialized = useRef(false)

    useEffect(()=>{
        if (!initialized.current) { // evitare doppie chiamate
            initialized.current = true
            if(!userId || userId === "" || !token || token === ""){
                return navigate("/")
            }
            handleUserRefRegistration();
        }
    },[userRef, userId])

    async function handleUserRefRegistration() {
        setWaiterView(true)
        if(userRef){
            const request:AddCreditsForReferralModelRequest = {
                userId: userId as string,
                token: token as string,
                userRef: userRef as string,
            }
            addCreditsForReferral(request).then((resp) => {
                console.log(resp)
                delay(3000).finally(()=>{
                    setWaiterView(false);
                    setMessage("Registrazione terminata e avvenuta con successo. Per essere operativo dovrai attendere di esser contattato via mail da un nostro operatore. Grazie.")
                })
            }).catch((e)=>{
                delay(3000).finally(()=>{
                    setWaiterView(false);
                    setMessageColor("red");
                    const message:any = e?.response?.data || e?.response || e || "Errore durante la fase finale della registrazione. fai un tentativo di refresh della pagina."
                    setMessage(message)
                })
            })
        }else{
            await delay(3000).finally(()=>{
                setWaiterView(false);
                setMessage("Registrazione terminata e avvenuta con successo. Per essere operativo dovrai attendere di esser contattato via mail da un nostro operatore. Grazie.")
            })
        }
    }


    return (
        <Box sx={{
            display:'flex',
            width:'100%',
            alignItems:'center',
            flexDirection:'column'
        }}>

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: BLUE_MAIN, color: 'white', alignItems:'center' }}>
                <Login sx={{fontSize:50}} />
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                {t("Registrazione Azienda o Associazione")}
            </Typography>
            
            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography variant="h4" color={messageColor}>
                {
                    message
                }
                </Typography>
            </Box>
            
            <Box
                sx={{
                    height: 40,
                }}
            />
        </Box>
    )
}