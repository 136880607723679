import React, {ReactNode, useEffect, useState} from 'react';
import { AppContainer, 
  ContainerCustom, 
  FooterContainer,
  WaiterContainer} from './template'
import { Grid, Typography, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import settings from '../utilities/settings';
import { BLACK, WHITE } from '../theme/colors';
import Header from '../components/Header';
import DrawerComponent from '../components/DrawerComponent';
import useHeaderHeight from '../hooks/UseHeaderHeight';
import CustomWaiter from '../components/Waiter';
import { useWaiter } from '../hooks/waiter/WaiterProvider';
import FooterNavigation from '../components/Footer/FooterNavigation';

interface LayoutProps{
  children: ReactNode
}
const Layout: React.FC<LayoutProps> = ({children}) =>{
  const headerHeight = useHeaderHeight();
  const [headerH, setHeaderH] = useState(0)
  const {waiterView} = useWaiter();
  
  useEffect(()=>{
    setHeaderH(headerHeight + 40)
  },[headerHeight])

  return (
    <>
      <AppContainer>
        <Header/>

        <ContainerCustom headerH={headerH}
          maxWidth='lg'
        >
          {children}
          {
            waiterView && <WaiterContainer>
              <CustomWaiter width={100} size={80}/>
            </WaiterContainer>
          }
        </ContainerCustom>
        
        { !isMobile ? (
            <FooterContainer>
              <Grid container>
                <Grid xs={12} item sx={{
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems: 'center'
                }}>
                  <Typography sx={{
                    fontSize:'0.70rem',
                    fontWeight:'bold',
                    color: BLACK
                  }}>{settings.Details.Owner.Name} &reg;</Typography>
                  <Typography sx={{
                    fontSize:'0.68rem',
                    color: BLACK
                  }}>{settings.Details.Owner.Address}</Typography>
                  <Typography sx={{
                    fontSize:'0.68rem',
                    color: BLACK
                  }}>{settings.Details.Owner.VAT}</Typography>
                </Grid>
              </Grid>
            </FooterContainer>
          ) : (
            <FooterContainer>
              <FooterNavigation />
            </FooterContainer>
          )
        }
      </AppContainer>
      <DrawerComponent />
    </>
  );
}

export default Layout;
