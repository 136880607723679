import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { IAssociationId } from '../../models/Associations';
import { associationsListDropDown } from '../../hooks/actions/associations';

interface SearchAssociationsProps {
  setSelectedAssociation: (value: { associationName: string; associationId: string } | null) => void;
  defaultAssociationName: string; 
  defaultAssociationId: string | undefined;
}

const SearchAssociations: React.FC<SearchAssociationsProps> = ({ setSelectedAssociation, defaultAssociationName,  defaultAssociationId}) => { //, associationError, setAssociationError 
    const { t } = useTranslation();
    const [accociations, setAssociations] = React.useState<IAssociationId[]>([])

    React.useEffect(()=>{
      associationsListDropDown().then(result => {
        setAssociations(result)
      }).catch(e=>{
        setAssociations([])
      })
    },[])

    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={accociations}
        getOptionLabel={(option) => option.descriptionName}
        clearOnEscape
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedAssociation({
              associationName: newValue.associationName,
              associationId: newValue.associationId,
            });
          } else {
            defaultAssociationId ? setSelectedAssociation({
              associationName: defaultAssociationName,
              associationId: defaultAssociationId,
            }): setSelectedAssociation(null);
          }
        }}
        renderInput={(params) => <TextField 
            margin="normal" 
            {...params} 
            required
            id='association'
            name="association"
            autoComplete="association"
            label={t("Associazione di appartenenza")} 
            error={false}
            // helperText={
            //   associationError ? t("Scelta Obbligatoria") : ""
            // }
            />
          }
        />
    );
  }

  export default SearchAssociations;