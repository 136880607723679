import { Typography } from '@mui/material';
import React, {useEffect} from 'react';
import { useAuth } from 'react-oidc-context';
import {Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(()=>{
        if(auth.isAuthenticated)
            navigate('/home')
    },[auth.isAuthenticated])

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <Typography variant='body1'>
                        Asso è un circuito economico delle Associazioni rivolto alle Associazioni, con finalità sociali.<br/>
                        Asso appartiene ad un network di Associazioni europee..
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default Home;