import { TypographyVariantsOptions } from "@mui/material";

export const worksans = [
	{
		fontFamily: "WorkSans",
		fontWeight: "normal",
		src: `url(/fonts/Work_Sans/static/WorkSans-Regular.ttf)`,
	},
	{
		fontFamily: "WorkSans",
		fontWeight: 600,
		src: `url(/fonts/Work_Sans/static/WorkSans-SemiBold.ttf)`,
	},
	{
		fontFamily: "WorkSans",
		fontWeight: "bold",
		src: `url(/fonts/Work_Sans/static/WorkSans-Bold.ttf)`,
	},
	{
		fontFamily: "WorkSans",
		fontWeight: 800,
		src: `url(/fonts/Work_Sans/static/WorkSans-ExtraBold.ttf)`,
	},
];

export const typography: TypographyVariantsOptions = {
	fontFamily: "WorkSans, Arial",
	fontSize: 16,
	h1: {
		fontSize: 46,
		fontWeight: 800,
		lineHeight: 1.2,
		letterSpacing: -0.25,
	},
	h2: {
		fontSize: 36,
		fontWeight: 800,
		lineHeight: 1.33,
		letterSpacing: "normal",
	},
	h3: {
		fontSize: 27,
		fontWeight: "bold",
		lineHeight: 1.19,
		letterSpacing: "normal",
	},
	h4: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: 1.23,
		letterSpacing: "normal",
	},
	h5: {
		fontSize: 18,
		fontWeight: "bold",
		lineHeight: 1.33,
		letterSpacing: "normal",
	},
	h6: {
		fontSize: 14,
		fontWeight: 600,
		lineHeight: 1.57,
		letterSpacing: "normal",
	},
	caption: {
		fontSize: 12,
		fontWeight: "normal",
		lineHeight: 1.33,
		letterSpacing: "normal",
	},
	body1: {
		fontSize: 20,
		fontWeight: "normal",
		lineHeight: 1.31,
		letterSpacing: "normal",
	},
	body2: {
		fontSize: 14,
		fontWeight: "normal",
		lineHeight: 1.29,
		letterSpacing: "normal",
	},
	subtitle1: {
		fontSize: 16,
		fontWeight: "bold",
		lineHeight: 1.38,
		letterSpacing: "normal",
	},
	subtitle2: {
		fontSize: 16,
		fontWeight: "bold",
		lineHeight: 1.38,
		letterSpacing: "normal",
	},
	overline: {
		fontSize: 18,
		fontWeight: "bold",
		lineHeight: "normal",
		letterSpacing: 0.5,
	},
	button: {
		fontSize: 14,
		fontWeight: "bold",
		lineHeight: "normal",
		letterSpacing: -0.1,
	},
};

export const fontWeightClasses = {
	"&.fontWeightBolder": {
		fontWeight: "800 !important",
	},
	"&.fontWeightBold": {
		fontWeight: "bold !important",
	},
	"&.fontWeightSemiBold": {
		fontWeight: "600 !important",
	},
	"&.fontWeightNormal": {
		fontWeight: "normal !important",
	},
};
