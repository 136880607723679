import React, {useEffect, useRef, useState} from 'react';
import {Box, Link, Grid, Card, CardContent, Button, IconButton, Typography} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WHITE } from '../../theme/colors';
import { dashboard, getPdfCredits } from '../../hooks/actions/dashboard';
import { LastTransaction } from '../../models/dashboard/Transactions';
import { formatDate } from '../../helpers/FormatDate';
import RefreshIcon from '@mui/icons-material/RefreshOutlined'; 
import { useWaiter } from '../../hooks/waiter/WaiterProvider';
import { delay } from '../../utilities/delay';
import { useUserContext } from '../../hooks/auth/UserProvider';
import { checkUserEnabled } from '../../helpers/authorizedUser';
import settings from '../../utilities/settings';
import CopyToClipboard from '../../components/CopyToClipboard';
import { checkUserAuth } from '../../hooks/actions/users';

const HomeAuth = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const initLoad = useRef(false);

    const [activities, setActivities] = React.useState<LastTransaction[]>([]);
    const [amount, setAmount] = React.useState<number>(0);
    const [pendingAmount, setPendingAmount] = React.useState<number>(0);
    const [points, setPoints] = React.useState<number>(0);
    const [referral, setReferral] = React.useState<string | null>(null);
    const {setWaiterView} = useWaiter();
    const {checkIsAuth} = useUserContext();
    const [isAssociation, setIsAssociation] = useState<boolean>(false);

    // utente autorizzato
    const {user, auth} = useUserContext();
    const [isEnabled, setIsEnabled] = React.useState(false)
    useEffect(()=>{
        if (!auth.isLoading) {
            if (!auth.isAuthenticated) {
                navigate('/');
            } else {
                if(user){
                    if(checkUserEnabled(user)){
                        const url = new URL(settings.ReferralBaseUrl);
                        const cleanedPathname = url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname;
                        url.pathname = `${cleanedPathname}/${user.refCode}`;
                        setIsEnabled(true);
                        setReferral(url.toString());
                    }

                    // Association
                    const associationRoles = ["Association"];
                    
                    const hasRole = (userRoles: string | string[] | undefined | null, rolesToCheck: string[]): boolean => {
                        if (Array.isArray(userRoles)) {
                            return userRoles.some((role: string) => rolesToCheck.includes(role));
                        } else if (typeof userRoles === "string") {
                            return rolesToCheck.includes(userRoles);
                        }
                        return false; // Se userRoles è undefined o null
                    };
                    if (hasRole(user.role, associationRoles)) {
                        setIsAssociation(true);
                    } else { setIsAssociation(false); }
                }else { setIsAssociation(false); }
            }
        }
        checkIsAuth().finally();
    },[auth.isAuthenticated, auth.isLoading, navigate, user])
    // fine utente autorizzato

    useEffect(()=>{
        if(!initLoad.current){
            initLoad.current = true
            dashboard()
                .then(resp => {
                    setActivities(resp.lastTransaction);
                    setPendingAmount(resp.pendingBalance);
                    setAmount(resp.balance)
                    setPoints(resp.credits)
                })
                .catch(error => {
                    initLoad.current = false
                    console.log(error)
                });
            
            checkIsAuth().finally();
        }
    },[])

    const handleRefresh = () => {
        setWaiterView(true)
        delay(500).finally(()=>{
            dashboard()
                .then(resp => {
                    setActivities(resp.lastTransaction);
                    setAmount(resp.balance)
                    setPendingAmount(resp.pendingBalance);
                    setPoints(resp.credits)
                })
                .catch(error => {
                    initLoad.current = false
                    console.log(error)
                })
            setWaiterView(false)
            checkIsAuth().finally();
        })
    };

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
            }}>
                {
                    auth.isAuthenticated && <Grid container spacing={3}>
                        {/* Prima colonna card */}
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                {/* Card Saldo Asso */}
                                <Grid item xs={12}>
                                    <Card sx={{ position: 'relative' }}>
                                        <CardContent sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.3rem',
                                        }}>
                                            {t('Saldo Asso')}
                                            {/* Pulsante di aggiornamento */}
                                            <IconButton
                                                onClick={handleRefresh}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    backgroundColor: 'transparent',
                                                    color:WHITE,
                                                    '&:hover': {
                                                        backgroundColor: 'lightblue', // Colore azzurro per hover
                                                    },
                                                }}
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </CardContent>
                                        <CardContent sx={{
                                            fontSize: '1.8rem',
                                            letterSpacing: '0.1rem',
                                        }}>
                                            {amount}
                                        </CardContent>
                                        {pendingAmount > 0 && 
                                        <>
                                            <CardContent sx={{
                                                fontSize: '1.2rem',
                                                letterSpacing: '0.1rem',
                                            }}>
                                                {t("Importo bloccato: ")} {pendingAmount}
                                            </CardContent>
                                        </>}
                                        <CardContent>
                                            {<Button variant="contained"
                                                onClick={()=>{navigate('/buy')}}
                                                disabled={!isEnabled}
                                                sx={{
                                                    margin:'10px',
                                                    backgroundColor: 'white',
                                                    color: 'blue',
                                                    maxWidth: '200px',
                                                    textTransform: 'none',
                                                    letterSpacing: '0.05rem',
                                                    '&:hover': {
                                                        backgroundColor: 'lightblue', // Colore azzurro per hover
                                                        color: 'blue',
                                                    },
                                                    '&.Mui-disabled': {
                                                        color: WHITE, 
                                                        cursor: 'not-allowed', 
                                                    },
                                                }}>
                                                {isEnabled ? t("Acquista Asso") : t("Non sei abilitato all'acquisto")}
                                            </Button>}
                                            {<Button variant="contained"
                                                onClick={()=>{navigate('/withdraw')}}
                                                disabled={false}
                                                sx={{
                                                    margin:'10px',
                                                    backgroundColor: 'white',
                                                    color: 'blue',
                                                    maxWidth: '200px',
                                                    textTransform: 'none',
                                                    letterSpacing: '0.05rem',
                                                    '&:hover': {
                                                        backgroundColor: 'lightblue', // Colore azzurro per hover
                                                        color: 'blue',
                                                    },
                                                    '&.Mui-disabled': {
                                                        color: WHITE, 
                                                        cursor: 'not-allowed', 
                                                    },
                                                }}>
                                                {t("Preleva Asso")}
                                            </Button>}
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Card Attività Recenti */}
                                <Grid item xs={12}>
                                    <Card  sx={{ position: 'relative' }}>
                                        <CardContent sx={{
                                            fontWeight:'bold',
                                            fontSize:'1.3rem',
                                        }}>
                                            {t("Attività Recenti")}
                                            {/* Pulsante di aggiornamento */}
                                            <IconButton
                                                onClick={handleRefresh}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    backgroundColor: 'transparent',
                                                    color:WHITE,
                                                    '&:hover': {
                                                        backgroundColor: 'lightblue', // Colore azzurro per hover
                                                    },
                                                }}
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </CardContent>
                                        <CardContent sx={{
                                        }}>
                                            {activities.length > 0 ? 
                                            <table style={{ width: '100%', borderCollapse: 'collapse', textAlign:'left', color:'lightgray' }}>
                                                <tbody>
                                                {activities.slice(0, 5).map((activity, index) => (
                                                    <tr key={index}>
                                                        <td style={{ paddingRight: '1rem', width:'45%' }}>
                                                            {formatDate(activity.data)}
                                                        </td>
                                                        <td style={{ width:'55%' }}>
                                                            {t(activity.description)}
                                                        </td>
                                                        <td style={{ width:'55%', textAlign:'right' }}>
                                                            {activity.amount.toFixed(2)} 
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            :
                                            t("Non hai ancora transazioni")
                                            }
                                        </CardContent>
                                        <CardContent>
                                            <Link href="/transactions" sx={{ color: WHITE, textDecoration: 'none' }}>
                                                {t("Mostra tutto")}
                                            </Link>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Seconda colonna card */}
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                {/* Card Punti Fedeltà */}
                                <Grid item xs={12}>
                                    <Card sx={{ position: 'relative' }}>
                                        <CardContent sx={{
                                            fontWeight:'bold',
                                            fontSize:'1.3rem',
                                        }}>
                                            {t('Punti Fedeltà')}
                                            {/* Pulsante di aggiornamento */}
                                            <IconButton
                                                onClick={handleRefresh}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    backgroundColor: 'transparent',
                                                    color:WHITE,
                                                    '&:hover': {
                                                        backgroundColor: 'lightblue', // Colore azzurro per hover
                                                    },
                                                }}
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </CardContent>
                                        <CardContent sx={{
                                            fontSize:'1.8rem',
                                            letterSpacing:'0.1rem',
                                        }}>
                                            {points}
                                        </CardContent>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Link sx={{ 
                                                        color: WHITE, textDecoration: 'none',
                                                            '&:hover': { 
                                                                cursor: 'pointer'
                                                            } 
                                                        }}
                                                    >
                                                        {t("Guadagna punti")}
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Link sx={{ 
                                                        color: WHITE, textDecoration: 'none',
                                                            '&:hover': { 
                                                                cursor: 'pointer'
                                                            } 
                                                        }}  
                                                        onClick={() => {getPdfCredits().finally()}}>
                                                        {t("Esporta")}
                                                    </Link>
                                                </Grid>
                                            </Grid> 
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {/* Card Referral Link */}
                                {!isAssociation && <Grid item xs={12}>
                                    <Card sx={{ position: 'relative' }}>
                                        <CardContent sx={{
                                            fontWeight:'bold',
                                            fontSize:'1.3rem',
                                        }}>
                                            {t('Il Tuo Referral')}
                                            
                                        </CardContent>
                                        <CardContent sx={{
                                            fontSize:'0.65rem',
                                            letterSpacing:'0.1rem',
                                        }}>
                                            {   
                                                referral &&
                                                <CopyToClipboard text={referral} />
                                            }
                                        </CardContent>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    {t("Clicca sul link per copiare il tuo referral link.")}
                                                </Grid>
                                            </Grid> 
                                        </CardContent>
                                    </Card>
                                </Grid>}

                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Box>
        </>
    )
}

export default HomeAuth;