import { useEffect } from "react";
import { Box, Avatar, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import Login from '@mui/icons-material/Login'
import { delay } from "../utilities/delay";
import { useAuth } from 'react-oidc-context';
import { BLUE_MAIN } from "../theme/colors";

export default function CallBack () {
    const { t } = useTranslation();
    const auth = useAuth();

    useEffect(()=>{
    },[])

    useEffect(() => {
		if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
			auth.signinRedirect();
		} else if (auth.isAuthenticated) {
			const url = (auth.user?.state as string) || "/";
			window.location.replace(url);
		}
	}, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    return (
        <Box sx={{
            display:'flex',
            width:'100%',
            alignItems:'center',
            flexDirection:'column'
        }}>

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: BLUE_MAIN, color: 'white', alignItems:'center' }}>
                <Login sx={{fontSize:50}} />
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                {t("Accesso")}
            </Typography>
            
            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography variant="h4">
                {
                    auth.activeNavigator || auth.isLoading ? (
                        t("Autenticazione in corso...")
                    ) : !auth.isAuthenticated ? (
                        t("Impossibile completare il login")
                    ) : (
                        <div>
                            {t("Accesso avvenuto correttamente.")}
                            <br />
                            {t("Verrai reindirizzato alla pagina in pochi secondi...")}
                        </div>
                    )
                }
                </Typography>
            </Box>
            
            <Box
                sx={{
                    height: 40,
                }}
            />
        </Box>
    )
}