import { Grid, TextField, Typography } from "@mui/material"
import { BLACK, GRAY_LIGHT_RGB_BORDER } from "../../theme/colors"
import { useTranslationContext } from "../../hooks/translations/translationsProvider"

interface BankTransferProps {
    setAmount: (value: React.SetStateAction<string>) => void;
    setError: (value: React.SetStateAction<boolean>) => void;
    typeTab: number;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, type: number) => void;
    handleBlur: (type: number) => void;
    amount: string;
    error: boolean;
    errorMessage: string;
    divisa: string;
    feeFixed: number;
    feePerc:number;
    feePercAmount:number;
    netAmount: number;
}

const BankTransfer:React.FC<BankTransferProps> = ({
    setAmount,
    setError,
    typeTab,
    handleInputChange,
    handleBlur,
    amount,
    error,
    errorMessage,
    divisa,
    feeFixed,
    feePerc,
    feePercAmount,
    netAmount
}) => {
const {t} = useTranslationContext();
    return(
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={t("Importo in Euro")}
                    name="amount"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {(handleInputChange(evt, typeTab ))}}
                    onClick={() => {
                        setAmount('')
                        setError(false)
                    }}
                    onBlur={() => handleBlur(typeTab)}
                    value={amount}
                    error={error}
                    // disabled={response.success!}
                    helperText={
                        error ? errorMessage : ''
                    }
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline':{
                            borderColor: GRAY_LIGHT_RGB_BORDER
                        }
                    }}
                />
            </Grid>
            { (amount !== "" && !error) &&
                <Grid item xs={12} color={BLACK}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"left"}   fontWeight={"bold"}>
                                { t("Totale") }
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"right"}  fontWeight={"bold"}>
                                {parseFloat(amount).toFixed(2)} {divisa}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container marginTop={0.5}>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"left"}>
                                {t("Commissione Fissa")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"right"}>
                                {feeFixed.toFixed(2)} {divisa}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container marginTop={0.5}>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"left"}>
                                {t("Commissione Percentuale")} {feePerc.toFixed(2)} %
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"right"}>
                                {feePercAmount.toFixed(2)} {divisa}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"left"}   fontWeight={"bold"}>
                                { t("Accredito netto") }
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" fontSize={"0.8rem"} textAlign={"right"}  fontWeight={"bold"}>
                                {netAmount.toFixed(2)} {divisa}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default BankTransfer;