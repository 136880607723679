import { useState } from 'react'
import RedeemForm from './RedeemForm'


export default function Redeem() {
  const [statusResponse, setStatusResponse] = useState<boolean>(false)

  return (
    <>
      <RedeemForm status={statusResponse} setStatus={setStatusResponse} />
    </>
  )
}