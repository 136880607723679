import React from 'react';
import { CircularProgress } from '@mui/material';

interface WaiterProps{
  width:number,
  size:number
}

const CustomWaiter:React.FC<WaiterProps> = ({width, size}) => {
  return (
    <div style={{ width: width+'px', height: width+'px' }}>
      <CircularProgress style={{  }} color="primary" size={size} thickness={5} />
    </div>
  );
};

export default CustomWaiter;