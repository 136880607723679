import { useState, useEffect } from 'react';

const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.getElementById('header');
    if (header) {
      const updateHeaderHeight = () => {
        setHeaderHeight(header.offsetHeight);
      };

      const resizeObserver = new ResizeObserver(() => {
        updateHeaderHeight();
      });

      resizeObserver.observe(header);

      // Initial update
      updateHeaderHeight();

      return () => {
        resizeObserver.unobserve(header);
      };
    }
  }, []);

  return headerHeight;
};

export default useHeaderHeight;