import { IUser } from "../hooks/auth/models";

const convertUserTypes = (user: IUser): IUser => {
    return {
      ...user,
      enableduser: user.enableduser === 'True' ? true :
                 user.enableduser === 'False' ? false :
                 user.enableduser === null || user.enableduser === undefined ? false :
                 user.enableduser === true ? true :
                 user.enableduser === false ? false :
                 false,
      approvedfromassociation: typeof user.approvedfromassociation === 'string' ? parseInt(user.approvedfromassociation, 10) : user.approvedfromassociation,
      approvedfromAdmin: typeof user.approvedfromAdmin === 'string' ? parseInt(user.approvedfromAdmin, 10) : user.approvedfromAdmin,
    };
};

export const checkUserEnabled = (_user: IUser) => {
    const user = convertUserTypes(_user);
    switch (user.userType) {
        case 'Private':
            if (!user.enableduser || user.approvedfromassociation !== 1) {
                return false;
            }
            return true;
        case 'Association':
        case 'Company':
            if (!user.enableduser || user.approvedfromAdmin !== 1) {
                return false;
            }
            return true;
        default:
            return false;
    }
      
};
  
export const checkIfUserIsCompanyOrAssociation = (_user: IUser):boolean => {
    const user = convertUserTypes(_user);
    switch (user.userType) {
        case 'Private':
                return false;
        case 'Association':
        case 'Company':
                return true;
        default:
            return false;
    }
}

