import React, {FormEvent} from "react";
import { styled, alpha } from "@mui/material";
import { BLUE_LIGHT_RGB_BORDER } from "../theme/colors";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useTranslationContext } from "../hooks/translations/translationsProvider";
import { platform } from "os";

interface SearchProps {
    handleInput: (input: string) => void;
    setSearch: (input:string) => void;
    search: string;
    palceHolder?: string;
}

const Container = styled('div')(({ theme }) => ({
    display:'flex',
    borderRadius: '5px',
    backgroundColor: alpha(theme.palette.primary.light, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
    marginLeft: 0,
    width: '100%',
    height:'35px',
    border:'1px solid '+BLUE_LIGHT_RGB_BORDER
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: 0,
    height: '100%',
    width:'35px',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'grey',
    flex:1,
}));

const Search = ({handleInput, setSearch, search, palceHolder}:SearchProps) => {
    const {t} = useTranslationContext();

    return (
        <Container>
            <SearchIconWrapper>
                <SearchIcon color="action" />
            </SearchIconWrapper>
            <StyledInputBase
                value={search}
                onClick={() => setSearch('')}
                onChange={(event)=>{handleInput(event.target.value)}}
                placeholder={palceHolder ? t(palceHolder) :t("Cerca…")}
                inputProps={{ 'aria-label': 'search' }}
            />
        </Container>
    )
}

export default Search