import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../hooks/auth/UserProvider';
import { checkUserEnabled } from '../../helpers/authorizedUser';
import WithdrawForm from '../../components/WithdrawForm';
import LastWithdrawTransactionsTable from '../../components/Withdraw/LastWithdrawTransactions';

const WithdrawTokens = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [statusResponse, setStatusResponse] = React.useState<boolean>(false)
    const {checkIsAuth} = useUserContext();

    // utente autorizzato
    const {user, auth} = useUserContext();
    useEffect(()=>{
        if (!auth.isLoading) {
            if (!auth.isAuthenticated) {
                navigate('/');
            } else {
                if(user){
                    if(!checkUserEnabled(user)){
                        navigate('/');
                    }
                }
                setLoading(false);
            }
        }
        checkIsAuth().finally();
    },[auth.isAuthenticated, auth.isLoading, navigate, user, ])
    // fine utente autorizzato

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                flexDirection:'column'
            }}>
                {
                    auth.isAuthenticated && 
                    <>
                        <WithdrawForm status={statusResponse} setStatus={setStatusResponse}/>
                        
                        <>
                            <LastWithdrawTransactionsTable status={statusResponse} />
                        </>
                        
                    </>
                }
            </Box>
        </>
    )
}

export default WithdrawTokens;