import httpClient from "../../utilities/http-client";
import { LegalEntity } from "../../models/circuito/LegacyEntity";
import { useState } from "react";
import { AddActivityTagRequestModel, LegacyMarket, Market, TagResponseModel } from "../../models/market/LegacyMarket";


export const marketsList = async () => {
	try {
		const { data } = await httpClient.get<LegacyMarket[]>("/activities");
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const marketsListSearch = async (search:string) => {
	try {
		const { data } = await httpClient.get<LegacyMarket[]>(`/activities/${search}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const myMarketsList = async () => {
	try {
		const { data } = await httpClient.get<LegacyMarket[]>(`/activities/my`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const myMarket = async (marketId: string) => {
	try {
		const { data } = await httpClient.get<Market>(`/activities/my/${marketId}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const mymarketDelete = async (marketId:string) => {
	try{
		const { data } = await httpClient.delete<LegacyMarket[]>(`/activities/${marketId}`);
		return data;
	}catch(e:any){
		throw e;
	}
}

export const addNewActivity = async (model:Market) => {
	try{
		const { data } = await httpClient.post<Market>(`/activities`, model);
		return data;
	}catch(e:any){
		throw e;
	}
}

export const putActivity = async (model:Market) => {
	try{
		const { data } = await httpClient.put<Market>(`/activities`, model);
		return data;
	}catch(e:any){
		throw e;
	}
}

// tags
export const myMarketTags = async (marketId: string) => {
	try {
		const { data } = await httpClient.get<TagResponseModel[]>(`/activities/tag/my/${marketId}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const searchTags = async (search: string) => {
	try {
		const { data } = await httpClient.get<string[]>(`/activities/tag/${search}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const addNewTag = async (model:AddActivityTagRequestModel) => {
	try{
		const { data } = await httpClient.post<TagResponseModel>(`/activities/tag/`, model);
		return data;
	}catch(e:any){
		throw e;
	}
}

export const myTagDelete = async (model:TagResponseModel) => {
	try{
		const { data } = await httpClient.delete<TagResponseModel>(`/activities/tag/`,{data:model});
		return data;
	}catch(e:any){
		throw e;
	}
}
