import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';

const StatusIco: React.FC<{status:string}> = ({ status }) => {
    const getStatusIcon = () => {
        switch (status) {
        case "Confirmed":
            return <CheckIcon style={{ color: green[500] }} />;
        case "Rejected":
            return <CancelIcon style={{ color: red[500] }} />;
        default:
            return null;
        }
    };

    return (
        <>
            {getStatusIcon()}
        </>
    );
};

  export default StatusIco;
  