import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import settings from '../../utilities/settings';
import { TFunction } from 'i18next';
import { getFromLocalStorage, setToLocalStorage } from '../../helpers/localStorage';

interface ITranslationContext {
  changeLanguage: (lng: string) => void;
  languages: LanguagesObject[];
  t: TFunction<"translation", undefined>
  getLanguage: () => string
}

export interface LanguagesObject {
  code: string,
  label: string
}

const TranslationContext = createContext<ITranslationContext | undefined>(undefined);

export const useTranslationContext = () => {
  const ctx = useContext(TranslationContext);
  if(!ctx) throw new Error("Error!!!!")
    else return ctx;
}

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState<LanguagesObject[]>([]);

  useEffect(()=>{
    setLanguages(settings.dropdownLanguages);
    const lang = getFromLocalStorage("DefaultLanguage")
    if(lang === null){
      setToLocalStorage("DefaultLanguage", i18n.language)
    }else{
      // se c'è una lingua in localstorage uso quella
      i18n.changeLanguage(lang);
    }
  },[]) 

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    //setto la lingua prescelta in Ls
    setToLocalStorage("DefaultLanguage", i18n.language)
  };

  const getLanguage = () => {
    return i18n.language
  }

  const contextValue = {
    t,
    changeLanguage,
    languages,
    getLanguage
  };

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
};
