import { Grid, Link, Typography } from "@mui/material";
import { useTranslationContext } from "../../hooks/translations/translationsProvider";
import { BLACK } from "../../theme/colors";
import { IBuy } from "../../models/buy/Buy";
import { IWithdrawResponseModel } from "../../models/withdraw";
import { useEffect } from "react";
import settings from "../../utilities/settings";

interface WithdrawResponseProsp {
    response: IWithdrawResponseModel;
}

const WithdrawResponse:React.FC<WithdrawResponseProsp> = ({response}) => {

    const {t} = useTranslationContext();
    let amount:number;
    useEffect(()=>{
        response.tokensAmount < 0 ? amount = response.tokensAmount * (-1) :  amount = response.tokensAmount
    },[])

    return(
        <>
            <Grid item xs={12} color={BLACK}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"}   fontWeight={"bold"}>
                            { t("Prelievo richiesto") } 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.8rem"} textAlign={"center"}>
                            {response.tokensAmount.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"}>
                            {t("Indirizzo Wallet ricevente")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link variant="body1" fontSize={"1rem"} textAlign={"center"}
                            href={`${settings.Token.Blockchain.ExplorerUrl}/address/${response.addressWallet}#tokentxns`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                wordBreak: 'break-word', // Interrompe le parole lunghe
                                overflow: 'hidden',      // Nasconde il testo oltre il limite
                                display: '-webkit-box',  // Imposta un box flessibile per il testo
                                WebkitLineClamp: 6,      // Limita a 6 righe
                                WebkitBoxOrient: 'vertical'  // Imposta l'orientamento del box
                              }}
                        >
                            {response.addressWallet}
                        </Link>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"}
                            sx={{
                                wordBreak: 'break-word', // Interrompe le parole lunghe
                                overflow: 'hidden',      // Nasconde il testo oltre il limite
                                display: '-webkit-box',  // Imposta un box flessibile per il testo
                                WebkitLineClamp: 6,      // Limita a 6 righe
                                WebkitBoxOrient: 'vertical'  // Imposta l'orientamento del box
                              }}
                        >
                            {t("Codice transazione")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"1rem"} textAlign={"center"}>
                            {response.refCode}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"}>
                            {t("Per completare il prelievo richiesto devi confermare l'operazione attraverso la mail che ti abbiamo inviato. Se entro 24 ore non effettuerai nessuna scelta verranno riaccreditati i fondi sul tuo account.")}
                        </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
        </>
    )
}

export default WithdrawResponse;