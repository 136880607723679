import { Grid, Typography } from "@mui/material";
import { useTranslationContext } from "../../hooks/translations/translationsProvider";
import { BLACK } from "../../theme/colors";
import { IBuy } from "../../models/buy/Buy";

interface BankTransferResponseProsp {
    response: IBuy;
    divisa: string
}

const BankTransferResponse:React.FC<BankTransferResponseProsp> = ({response, divisa}) => {

    const {t} = useTranslationContext();

    return(
        <>
            <Grid item xs={12} color={BLACK}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"}   fontWeight={"bold"}>
                            { t("Importo da bonificare") }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.8rem"} textAlign={"center"}>
                            {response.amount.toFixed(2)} {divisa}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"}>
                            {t("Intestatario del conto")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"1rem"} textAlign={"center"}>
                            {response.ibanOwner?.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"}>
                            {t("IBAN dove inviare il Bonifico")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"1rem"} textAlign={"center"}>
                            {response.ibanReceiver?.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"}   fontWeight={"bold"}>
                            { t("Banca") }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.8rem"} textAlign={"center"}>
                            {response.bankInfosReceiver}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"}   fontWeight={"bold"}>
                            BIC / SWIFT
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.8rem"} textAlign={"center"}>
                            {response.bicSwiftReceiver?.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container marginTop={4}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"}>
                            {t("Oggetto del bonifico")}
                        </Typography>
                        <Typography variant="body1" fontSize={"0.9rem"} textAlign={"center"} fontWeight={"bold"} color={"red"} marginTop={0.5}> 
                            {t("(VA INSERITO OBBLIGATORIAMENTE COME CAUSALE - se lo ometti potremmo non trovare il tuo bonifico e di conseguenza non accreditarti l'importo)")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop={0.5}>
                        <Typography variant="body1" fontSize={"1.1rem"} textAlign={"center"} fontWeight={"bold"}>
                            {response.refCode}
                        </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
        </>
    )
}

export default BankTransferResponse;