import { ECompanyOrAssociationStatus } from "../models/user/UserInfosModel";

enum AssociationStatus {
    FirstRequest = 0,
    ApprovedFromAssociation = 1,
    RemovedFromAssociation = 2,
    UserWithoutAssociation = 3,
  }
  
  // Mappa di traduzioni
  const associationStatusTranslations: { [key in AssociationStatus]: string } = {

    [AssociationStatus.FirstRequest]: "Richiesta in attesa di approvazione",
    [AssociationStatus.ApprovedFromAssociation]: "Utente approvato dall'associazione",
    [AssociationStatus.RemovedFromAssociation]: "Utente rimosso dall'associazione",
    [AssociationStatus.UserWithoutAssociation]: "Utente senza associazione",
  };
  
  // Funzione che restituisce la stringa tradotta
  export function getAssociationStatusLabel(status: number | null | undefined): string {
    if (status === null || status === undefined) {
        return "Stato sconosciuto";
    }
    return associationStatusTranslations[status as AssociationStatus] || "Stato sconosciuto";
  }



  const companyStatusTranslations: { [key in ECompanyOrAssociationStatus]: string } = {

    [ECompanyOrAssociationStatus.FirstRequest]: "Richiesta in attesa di approvazione",
    [ECompanyOrAssociationStatus.ApprovedFromAdmin]: "Azienda o Associazione approvata dall'amministrazione",
    [ECompanyOrAssociationStatus.LockedFromAdmin]: "Azienda o Associazione bloccata dall'amministrazione",
  };

  export function getCompanyStatusLabel(status: number | null | undefined): string {
    if (status === null || status === undefined) {
        return "Stato sconosciuto";
    }
    return companyStatusTranslations[status as ECompanyOrAssociationStatus] || "Stato sconosciuto";
  }
  