import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField, Chip, Box, Typography } from '@mui/material';
import { TagResponseModel, AddActivityTagRequestModel } from '../../models/market/LegacyMarket';
import { addNewTag, myMarketTags, myTagDelete, searchTags } from '../../hooks/actions/market';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';

const TagManager = ({ marketId }: { marketId: string }) => {
  const [tags, setTags] = useState<TagResponseModel[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [error, setError]= useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const {t} = useTranslationContext();
  // const [optionValue, setOptionValue] = useState<string | null>("");

  // Fetch initial tags on component load
  useEffect(() => {
    setError(false);
    setErrorMsg('');
    const fetchTags = async () => {
      try {
        const response = await myMarketTags(marketId);
        setTags(response);
      } catch (error:any) {
        const errorMex = error?.response?.data?.title ||
                            error?.response?.data?.message ||
                            error?.response?.data ||
                            error?.response ||
                            t("Errore durante la gestione dei tag")
        setErrorMsg(t(errorMex));
        setError(true);
      }
    };
    fetchTags();
  }, [marketId]);

  // Fetch tag suggestions with debounce
  useEffect(() => {
    setError(false);
    setErrorMsg('');
    if (search.length >= 2) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(async () => {
        try {
          const response = await searchTags(search);
          setSuggestions(response);
        } catch (error:any) {
            const errorMex = error?.response?.data?.title ||
                        error?.response?.data?.message ||
                        error?.response?.data ||
                        error?.response ||
                        t("Errore durante la gestione dei tag")
            setErrorMsg(t(errorMex));
            setError(true);
        }
      }, 500); // 1.2 seconds debounce time
    } else {
      setSuggestions([]);
    }

    return () => {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    };
  }, [search]);

  // Handle tag submission (POST)
  const handleAddTag = async (selectedTag: string | null) => {
    setError(false);
    setErrorMsg('');
    if (selectedTag) {
      const newTag: AddActivityTagRequestModel = {
        tag: selectedTag,
        marketId,
      };
      try {
        const response = await addNewTag(newTag); // Call server and get response with marketTagId
        setTags((prev) => [...prev, response]); // Update state only after successful response
        setSearch(''); // Clear search field
      } catch (error:any) {
        const errorMex = error?.response?.data?.title ||
                        error?.response?.data?.message ||
                        error?.response?.data ||
                        error?.response ||
                        t("Errore durante la gestione dei tag")
            setErrorMsg(t(errorMex));
            setError(true);
      }
    }
  };

  // Handle chip deletion (DELETE)
  const handleDeleteTag = async (tagToDelete: TagResponseModel) => {
    setError(false);
    setErrorMsg('');
    try {
      await myTagDelete(tagToDelete); // Delete from server
      setTags((prev) => prev.filter((tag) => tag.marketTagId !== tagToDelete.marketTagId)); // Remove tag only if delete is successful
    } catch (error:any) {
        const errorMex = error?.response?.data?.title ||
                error?.response?.data?.message ||
                error?.response?.data ||
                error?.response ||
                t("Errore durante la gestione dei tag")
        setErrorMsg(t(errorMex));
        setError(true);
    }
  };

  return (
    <Box mt={5}>
      {/* Tag input with Autocomplete */}
      <Autocomplete
        freeSolo
        options={suggestions}

        autoComplete
        autoSelect

        inputValue={search}
        onInputChange={(event, newInputValue) => {
            setSearch(newInputValue)
          }
        }
        onChange={(event, newValue) => {
          setSearch(newValue || '');
        }}
        onHighlightChange={(event, option) => {
          setError(false);
          setErrorMsg("");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Aggiungi Tag")}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && search) {
                if(search && (search.length > 2 || search.toUpperCase() === "AI" || search.toUpperCase() === "IA" || search.toUpperCase() === "IT"))
                {
                    handleAddTag(search); 
                    event.preventDefault(); 
                }
                else{
                    const msgErr = t("Il Tag richiede minimo 3 caratteri");
                    setError(true);
                    setErrorMsg(msgErr);
                }
              }
            }}
          />
        )}
      />

      {/* Display existing tags */}
      <Box mt={2}>
        {tags.map((tag) => (
          <Chip
            key={tag.marketTagId}
            label={tag.tag}
            onDelete={() => handleDeleteTag(tag)}
            sx={{
                margin:'5px'
            }}
          />
        ))}
      </Box>

      {
          error && <>
            <Typography
              variant="body2" // Tipo di testo
              color="error"   // Colore rosso per il messaggio di errore
              sx={{
                maxHeight: '6.5em',        // Limite massimo di altezza per circa 5 righe (1.3em per linea)
                overflow: 'hidden',        // Nasconde l'overflow
                textOverflow: 'ellipsis',  // Aggiunge i puntini di sospensione se il testo è troppo lungo
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,        // Limita il numero massimo di righe a 5
                lineHeight: '1.3em',       // Altezza della riga per calcolare il numero di righe
                wordWrap: 'break-word',    // Va a capo automaticamente
                paddingTop:'20px'
              }}
            >
            {errorMsg}
          </Typography>
        </>
        }
    </Box>
  );
};

export default TagManager;
