import * as React from 'react';
import { useAuth } from "react-oidc-context";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Avatar } from '@mui/material';
import { useUserContext } from '../hooks/auth/UserProvider';
import { BLACK, WHITE } from '../theme/colors';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useDrawer } from '../hooks/drawer/DrawerProvider';
import { useNavigate } from 'react-router-dom';


export default function DrawerComponent() {
    const auth = useAuth()
    const {closeDrawer, isOpen, menuItems,title} = useDrawer();
    const navigate = useNavigate();

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" >
            <ListItem alignItems='center'>
                <ListItemText primary={title} />
            </ListItem>
            {menuItems.map((value, index) => (
                <React.Fragment key={index}>
                    <List>
                        {value?.map((subVal, subIndex) => (
                            <React.Fragment>
                                <ListItem disablePadding>
                                    <ListItemButton 
                                        onClick={
                                            () => {navigate(subVal.navigation)} 
                                        }>
                                        {subVal.avatar && 
                                            <Avatar sx={{ width:'30px', height:'30px', border:'1px solid black', backgroundColor: WHITE, color: BLACK, alignItems:'center', marginRight:'10px' }}>
                                                {subVal.avatar}
                                            </Avatar>
                                        }
                                        <ListItemText primary={subVal.text} />
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                    {value && <Divider />}
                </React.Fragment>
            ))}


            
        </Box>
    );

    return (
        <>
            <Drawer open={isOpen} onClose={closeDrawer}>
                {DrawerList}
            </Drawer>
        </>
    );
}