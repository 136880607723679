import cookie, { CookieAttributes } from "js-cookie";
import { SignoutRedirectArgs, User, UserManager,UserManagerSettings } from "oidc-client-ts";
import React, { useEffect, useRef, useState, useContext, ReactNode } from "react";
import { IUser } from "./models";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { removeFromLocalStorage } from "../../helpers/localStorage";
import settings from "../../utilities/settings";
import { checkUserAuth } from "../actions/users";
import { useWeb3Context } from "@w3connector/web3provider";

interface IUserContext {
	user: IUser | undefined;
	logout: () => void;
	auth: AuthContextProps;
	checkIsAuth: () => Promise<void>;
}
const UserContext = React.createContext<IUserContext | undefined>(undefined);

// Context
export const useUserContext = () => {
    const ctx = useContext(UserContext);
    if(!ctx) {
        throw new Error('User Context Error');
    }
    return ctx;
}

interface UserProviderProps {
	children: ReactNode
}

// Provider
export const UserProvider:React.FC<UserProviderProps> = ({ children }) => { 
	const [user, setUser] = useState<IUser | undefined>();
	const auth = useAuth();
	const navigate = useNavigate();
	const {  
        modalOpen,
        connectors,
        web3Error,
        web3Hooks,
        chainName,
        setModalOpen,
        setWeb3Error,
        handleDisconnect } = useWeb3Context();
	
	useEffect(() => {
		if(auth.isAuthenticated){
			if(auth.user)
				setAccessToken(auth.user.access_token);
		}
	}, [auth.isAuthenticated]);

	const setAccessToken = (token: string) => {
		const decoded:IUser = jwtDecode(token);
		if (decoded.privateInfo) {
			decoded.privateInfo = JSON.parse(decoded.privateInfo as unknown as string);
		}
		if (decoded.companyAssociationInfo) {
			decoded.companyAssociationInfo = JSON.parse(decoded.companyAssociationInfo as unknown as string);
		}
		setUser(decoded);
		cookie.set("Authorization", "Bearer " + token);
	};

	const logoutParams:SignoutRedirectArgs = {
		id_token_hint:auth.user?.id_token
	}
	
	const logout = () => {
		if(web3Hooks.isActive){
			handleDisconnect().finally();
		}
      	auth.signoutSilent();
		//auth.signoutRedirect(logoutParams);
		auth.removeUser();
		cookie.remove("Authorization");
		cookie.remove("idsrv.session")
		cookie.remove(".AspNetCore.Identity.Application")
		removeFromLocalStorage('oidc.user:'+settings.Domains.IdentityClient+":"+settings.oidc.clientId)	
		setUser(undefined);
      	navigate("/")
	}

	const checkIsAuth = async () => {	  
		try {
		  	await checkUserAuth();
		} catch (e: any) {
			if (e.response?.status === 401) {
				await logout();
			}
		}
	  };

	return (
		<UserContext.Provider value={{user, logout, auth, checkIsAuth}}>
			{children}
		</UserContext.Provider>
	);
};