import { Container, styled, Box, Grid } from '@mui/material';
import { WHITE, BLUE_LIGHT_RGB_BORDER, BLUE_DARK, BLACK } from '../../theme/colors';
import { isMobile } from 'react-device-detect';
import { breakpoints } from '../../theme/breakpoints';


interface HeaderContainerProps {
  isScrolled?:boolean
  menuMobileOpen?: boolean
}
  
export const HeaderContainer = styled('div')<HeaderContainerProps>(({theme, isScrolled=false, menuMobileOpen=false})=>({
  top:'0px',
  left:'0px',
  right:'0px',
  position:'fixed',
  display:'flex',
  height: !isMobile ? (!isScrolled ? '125px' : '70px') : (menuMobileOpen ? '120px' : '65px'),
  flexDirection: !isMobile ? (!isScrolled ? 'column' : undefined): 'column',
  backgroundColor:WHITE,
  borderBottom: "1px solid "+BLUE_LIGHT_RGB_BORDER,
  zIndex:1000,
  transition: !isMobile ? 'height 0.3s ease' : undefined,
}))

export const ContainerHiddenSubHeader = styled('div')<HeaderContainerProps>(({theme, isScrolled=false})=>({
  width:'100%',
  display: !isMobile ? (!isScrolled ? 'flex' : 'none') : 'none',
  height:!isScrolled ? '35px' : '0px',
  backgroundColor:WHITE,
  alignItems:'center',
  padding:'8px 0 0 0',
  transition: 'height 0.3s ease', 
}))

export const Logo = styled('img')<HeaderContainerProps>(({theme, isScrolled=false})=>({
  height: !isMobile ? (!isScrolled ? '90px' : '60px') : '50px',
  marginTop:!isMobile ? (!isScrolled ? '-45px': '0px') : '0px',
  transition: 'height 0.5s ease', 
  [theme.breakpoints.only('xs')]: {
    marginTop: !isMobile ? '0px' : undefined,
    height: !isMobile ? (!isScrolled ? '70px' : '60px') : '50px',
  },
}))



export const GridLogin = styled(Grid)(({theme})=>({
  width:'120px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  padding:'0px !important',
  [theme.breakpoints.only('xs')]:{
    width:'100px'
  }
}))

export const GridMenu = styled(Grid)(({theme})=>({
  width:'100px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  padding:'0px !important',
  [theme.breakpoints.up('xs')]:{
    
  }
}))


interface GridDimensionProps {
  breackpoint: keyof typeof breakpoints.values
}
export const GridMenuHiddenDown = styled(Grid)<GridDimensionProps>(({theme,breackpoint})=>({
  // width:'auto !important',
  fontSize:'0.85rem',
  '&:hover': {
    color: BLUE_DARK,
  },
  '&.selected': {
    color: BLUE_DARK,
  },
  [theme.breakpoints.down(breackpoint)]:{
    display:'none'
  },

  [theme.breakpoints.up(breackpoint)]:{
    // width:'100px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    padding:'0 5px !important',
  }
}))

export const GridMenuHiddenUp = styled(Grid)<GridDimensionProps>(({theme,breackpoint})=>({
  fontSize:'0.85rem',
  '&:hover': {
    color: BLUE_DARK,
  },
  '&.selected': {
    color: BLACK,
    
  },

  [theme.breakpoints.up(breackpoint)]:{
    display:'none'
  },

  [theme.breakpoints.down(breackpoint)]:{
    // width:'100px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    padding:'0 5px !important',
  }
}))
  