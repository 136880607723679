import React from 'react';
import { Box, Typography, Grid, Paper, IconButton } from '@mui/material';
import { MailOutline, Phone, LocationOn } from '@mui/icons-material';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import settings from '../utilities/settings';

const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Utilizza tutta l'altezza disponibile
    padding: 3,
    textAlign: 'center'
};

const Contacts = () => {
    const {t, getLanguage} = useTranslationContext();
    return (
        <>
            <Box sx={{ 
                width:'100%', 
                display:'flex', 
                flexDirection:'column'
            }}>
                <Typography variant="h4" gutterBottom align="center">
                    {t("Contattaci")}
                </Typography>
                <Box sx={{height:'40px'}}/>

                <Grid container spacing={8} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={cardStyle}>
                            <IconButton color="primary" sx={{ fontSize: 40 }}>
                                <LocationOn />
                            </IconButton>
                            <Typography variant="h6" sx={{ marginTop: 2 }}>
                                {t("Indirizzo")}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: 1 }}>
                                {settings.Contacts.Address.Address} {settings.Contacts.Address.Number}, <br />
                                {settings.Contacts.Address.CAP} {settings.Contacts.Address.City} ({settings.Contacts.Address.Province}), {settings.Contacts.Address.Nation}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={cardStyle}>
                            <IconButton color="primary" sx={{ fontSize: 40 }}>
                                <MailOutline />
                            </IconButton>
                            <Typography variant="h6" sx={{ marginTop: 2 }}>
                                {t("Email")}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: 1 }}>
                            {settings.Contacts.Mail}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={cardStyle}>
                            <IconButton color="primary" sx={{ fontSize: 40 }}>
                                <Phone />
                            </IconButton>
                            <Typography variant="h6" sx={{ marginTop: 2 }}>
                                {t("Telefono")}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: 1 }}>
                            {settings.Contacts.Telephone}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Contacts;