import React, {useEffect, useRef} from 'react';
import {Box, Link, Grid, Card, CardContent, Button, IconButton} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TransactionTable from '../../components/Dashboard/TransactionsTable';
import BuyTransactionTable from '../../components/Buy/TransactionsTable';
import { useUserContext } from '../../hooks/auth/UserProvider';


const TransactionsAuth:React.FC<{type:number}> = ({type}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const {checkIsAuth}= useUserContext();

    useEffect(()=>{
        if (!auth.isLoading) {
            if (!auth.isAuthenticated) {
                navigate('/');
            } else {
                setLoading(false);
            }
        }
        checkIsAuth().finally();
    },[auth.isAuthenticated, auth.isLoading, navigate])

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                justifyContent:'center'
            }}>
                {
                    auth.isAuthenticated && 
                    type === 0 ? <TransactionTable /> : 
                    type === 1 ? <BuyTransactionTable /> : null
                }
            </Box>
        </>
    )
}

export default TransactionsAuth;