import httpClient from "../../utilities/http-client";
import { LegalEntity } from "../../models/circuito/LegacyEntity";
import { useState } from "react";
import { MarketInfo } from "../../models/circuito/MarketsGeo";


export const associationsList = async () => {
	try {
		const { data } = await httpClient.get<LegalEntity[]>("/associations");
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const associationsListSearch = async (search:string) => {
	try {
		const { data } = await httpClient.get<LegalEntity[]>(`/associations/${search}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};


export const geoCircuitSearch = async (latitudemin:number, longitudemin:number, latitudemax:number, longitudemax:number) => {
	try {
		const { data } = await httpClient.get<MarketInfo[]>(`/activities/geo/${latitudemin}/${longitudemin}/${latitudemax}/${longitudemax}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};
