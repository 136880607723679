import { AddCreditsForReferralModelRequest } from "../../models/credits/CreditsModels";
import httpClient from "../../utilities/http-client";

export const addCreditsForReferral = async (model: AddCreditsForReferralModelRequest) => {
	try {
		const { data } = await httpClient.post(`/credits/add/referral/`, model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};
