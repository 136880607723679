import React, { useState, useEffect } from 'react';
import {Box, Typography, Grid, Button, Card} from '@mui/material';
import { BLUE_DARK, BLUE_LIGHT, BLUE_LIGHT_RGB_BORDER, BLUE_MAIN } from '../../theme/colors';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import settings from '../../utilities/settings';
import { getFromLocalStorage } from '../../helpers/localStorage';
import { useAuth } from 'react-oidc-context';

const Mercato = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslationContext();
    const location = useLocation();
    const [url, setUrl]= useState<string>('');
    const navigate = useNavigate();
    const ReturnUrl = settings.Domains.ServiceClient + "/company/register/callback"
    let referral: string | undefined = getFromLocalStorage("Referral");
    const auth = useAuth();

    useEffect(()=>{
        if(auth.isAuthenticated)
            navigate('/markets')
    },[auth.isAuthenticated])

    useEffect(()=>{
        setUrl(`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`)
    },[location])

    return(
        <>
            <Box sx={{
                width:'100%', 
                display:'flex', 
                alignItems:'flex-start'
            }}>
                <Box sx={{
                    width:'100%', 
                    display:'flex', 
                    justifyContent:'center',
                    flexDirection:'column',
                }}>
                    <Typography variant='body1'>
                        Asso è un circuito economico con al centro un proprio Market-Place, con migliaia di prodotti in vendita e consegna a domicilio.<br />
                        Non solo, è un circuito di aziende, negozi fisici o online convenzionati dove poter acquistare prodotti o servizi utilizzando il Token del circuito Asso.
                    </Typography>

                    <Box sx={{height:'80px'}}/>
                    <Grid container spacing={1} justifyContent={'space-around'}>
                        <Grid item xs={6} md={6} lg={4}> 
                            <Card 
                                sx={{
                                    height:'80px', 
                                    cursor: 'pointer', 
                                    '&:hover': {
                                        backgroundColor: BLUE_LIGHT,
                                    },
                                }} onClick={()=>{navigate("/markets")}}>
                                    {t("VEDI IL MERCATO")}
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={6} lg={4}>
                            <Card 
                                sx={{
                                    height:'80px', 
                                    cursor: 'pointer', 
                                    backgroundColor: !isOpen ? undefined : BLUE_LIGHT_RGB_BORDER,
                                    color: !isOpen ? undefined : BLUE_MAIN,
                                    '&:hover': {
                                        backgroundColor: BLUE_LIGHT,
                                    },
                                }} onClick={()=>{setIsOpen(!isOpen)}}>
                                    {t("ACCEDI AL MERCATO")}
                            </Card>
                        </Grid>
                    </Grid>

                    {isOpen && 
                        <>
                            <Box sx={{height:'80px'}} />
                            <Grid container spacing={1} justifyContent={'space-around'}>
                                <Grid item xs={10} sm={6} md={4}>
                                    <Card 
                                        sx={{
                                            height:'30px', 
                                            cursor: 'pointer', 
                                            '&:hover': {
                                                backgroundColor: BLUE_LIGHT,
                                            },
                                        }} onClick={()=>{
                                            window.location.href = settings.Domains.IdentityClient + `/account/register?ReturnUrl=${url}`
                                        }}>
                                            {t("Registrati come Privato")}
                                    </Card>
                                </Grid>
                                <Grid item xs={10} sm={6} md={4}>    
                                    <Card 
                                        sx={{
                                            height:'30px', 
                                            cursor: 'pointer', 
                                            '&:hover': {
                                                backgroundColor: BLUE_LIGHT,
                                            },
                                        }} onClick={()=>{
                                            const url = referral ? settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&Type=2&UserRef=${referral}`: settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&Type=2`
                                            window.location.href = url
                                        }}>
                                            {t("Registrati come Azienda")}
                                    </Card></Grid>
                                <Grid item xs={10} sm={6} md={4}>   
                                    <Card 
                                        sx={{
                                            height:'30px', 
                                            cursor: 'pointer', 
                                            '&:hover': {
                                                backgroundColor: BLUE_LIGHT,
                                            },
                                        }} onClick={()=>{
                                            const url = referral ? settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&Type=1&UserRef=${referral}`: settings.Domains.IdentityClient + `/account/register-company?ReturnUrl=${ReturnUrl}&Type=1`
                                            window.location.href = url
                                        }}>
                                            {t("Registrati come Associazione")}
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}

export default Mercato;