import React from 'react';
import { Modal, Box, Typography, Button, Card, Grid } from '@mui/material';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { WHITE } from '../../theme/colors';
import { Market } from '../../models/market/LegacyMarket';

interface AlertDeleteModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  market: Market | null;
}

const AlertDeleteModal: React.FC<AlertDeleteModalProps> = ({ open, onClose, onDelete, market }) => {
    const {t} = useTranslationContext();

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width:'70%',
                maxWidth: "400px",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2">
                    {t("Attenzione")}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {t("Sei sicuro di voler cancellare questa attività?")}
                </Typography>
                {
                    market && 
                    <Typography sx={{ mt: 0 }}>
                        <strong>{market.marketName}</strong>
                    </Typography>
                }
                <Grid container justifyContent="space-around" spacing={1} marginTop={2}>
                    <Grid item xs={12}>
                        <Button variant="outlined" onClick={onClose}>{t("Annulla")}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="info" onClick={onDelete} sx={{color:WHITE}}>{t("Rimuovi Attività")}</Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );    
};

export default AlertDeleteModal;
