import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import LanIcon from '@mui/icons-material/Lan';
import StoreIcon from '@mui/icons-material/Store';
import ContactsIcon from '@mui/icons-material/ContactPage';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../hooks/auth/UserProvider';

const FooterNavigation: React.FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate(); // Hook per la navigazione
  const {auth} = useUserContext();

  const handleNavigation = (newValue: number) => {
    setValue(newValue);
    // Gestione della navigazione in base al pulsante selezionato

    switch (newValue) {
      case 0:
        navigate('/'); // Naviga alla home
        break;
      case 1:
        auth.isAuthenticated ? navigate('/buy') : navigate('/network'); // Esempio di navigazione a /favorites
        break;
      case 2:
        auth.isAuthenticated ? navigate('/withdraw') : navigate('/market'); // Esempio di navigazione a /search
        break;
      case 3:
        auth.isAuthenticated ? navigate('/profile') : navigate('/contacts'); // Esempio di navigazione a /profile
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
      {auth.isAuthenticated ?
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                handleNavigation(newValue)
            }}
            showLabels
        >
            <BottomNavigationAction label="Home" icon={<HomeIcon />} disabled={!auth.isAuthenticated} />
            <BottomNavigationAction label="Acquista" icon={<PaymentIcon />} disabled={!auth.isAuthenticated} />
            <BottomNavigationAction label="Preleva" icon={<AccountBalanceWalletIcon />} disabled={!auth.isAuthenticated} />
            <BottomNavigationAction label="Profilo" icon={<PersonIcon />} disabled={!auth.isAuthenticated} />
        </BottomNavigation> :
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                handleNavigation(newValue)
            }}
            showLabels
        >
            <BottomNavigationAction label="Home" icon={<HomeIcon/>}/>
            <BottomNavigationAction label="Circuito" icon={<LanIcon />} />
            <BottomNavigationAction label="Mercato" icon={<StoreIcon />} />
            <BottomNavigationAction label="Contatti" icon={<ContactsIcon />} />
        </BottomNavigation>
      }
    </Box>
  );
};

export default FooterNavigation;
